import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";

declare var echarts: any;

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})
export class ProjectDashboardComponent implements OnInit {
// Riferimenti ai contenitori dei grafici (blocco 3 e 4)
  @ViewChild('barChartContainer', { static: true }) barChartContainer!: ElementRef;
  @ViewChild('pieChartContainer', { static: true }) pieChartContainer!: ElementRef;
  @ViewChild('stackedBarChartContainer', { static: true }) stackedBarChartContainer!: ElementRef;

  constructor(public router: Router) { }

  ngAfterViewInit(): void {
    this.initBarChart();
    this.initPieChart();
    this.initStackedBarChart();
  }

  private initStackedBarChart(): void {
    const stackedBarChart = echarts.init(this.stackedBarChartContainer.nativeElement);
    const stackedBarOption = {
      title: {
        text: 'Therapeutic Areas',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow' // Mostra le aree di evidenziazione per asse
        }
      },
      legend: {
        orient: 'horizontal',
        bottom: 10,
        data: ['Board', 'Delphi Panel']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '15%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: ['Diabetes', 'Rare Diseases', 'Oncology']
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value} %'
        }
      },
      series: [
        {
          name: 'Board',
          type: 'bar',
          stack: 'total',
          data: [40, 50, 60],
          itemStyle: { color: '#4caf50' }
        },
        {
          name: 'Delphi Panel',
          type: 'bar',
          stack: 'total',
          data: [60, 50, 40],
          itemStyle: { color: '#ff9800' }
        }
      ]
    };

    stackedBarChart.setOption(stackedBarOption);
  }


  private initBarChart(): void {
    const barChart = echarts.init(this.barChartContainer.nativeElement);

    // Dati dei progetti
    const categories = ['Project 1', 'Project 2', 'Project 3', 'Project 4', 'Project 5', 'Project 6', 'Project 7'];
    const values = [18, 22, 15, 10, 12, 25, 20];

    // Ordina i dati per valori decrescenti
    const sortedData = categories
      .map((category, index) => ({ category, value: values[index] }))
      .sort((a, b) => a.value - b.value);

    const sortedCategories = sortedData.map(item => item.category);
    const sortedValues = sortedData.map(item => item.value);

    const barOption = {
      title: {
        text: 'Clinicians Engagement',
        left: 'center'
      },
      tooltip: {},
      xAxis: {
        type: 'value'
      },
      yAxis: {
        type: 'category',
        data: sortedCategories
      },
      series: [{
        name: 'Value',
        type: 'bar',
        data: sortedValues,
        itemStyle: { color: '#3f51b5' }
      }],
      grid: {
        left: '3%',
        right: '4%',
        bottom: '20%',
        containLabel: true
      }
    };

    barChart.setOption(barOption);
  }




  private initPieChart(): void {
    const pieChart = echarts.init(this.pieChartContainer.nativeElement);
    const pieOption = {
      tooltip: {
        trigger: 'item'
      },
      title: {
        text: 'Project Types',
        left: 'center'
      },
      legend: {
        orient: 'vertical',
        left: 'left'
      },
      series: [
        {
          name: 'Analysis',
          type: 'pie',
          radius: '50%',
          data: [
            { value: 40, name: 'Delphi Panel' },
            { value: 30, name: 'Advisory Board' },
            { value: 20, name: 'Clinical Trials' },
            { value: 10, name: 'Preceptorships' }
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    pieChart.setOption(pieOption);
  }

  ngOnInit(): void {
  }

  exportDataToJSON(): void {
    // Dati per Bar Chart
    const barChartData = {
      title: 'Clinicians Engagement',
      data: [
        { project: 'Project 1', value: 18 },
        { project: 'Project 2', value: 22 },
        { project: 'Project 3', value: 15 },
        { project: 'Project 4', value: 10 },
        { project: 'Project 5', value: 12 },
        { project: 'Project 6', value: 25 },
        { project: 'Project 7', value: 20 }
      ]
    };

    // Dati per Pie Chart
    const pieChartData = {
      title: 'Project Types',
      data: [
        { type: 'Delphi Panel', value: 35 },
        { type: 'Advisory Board', value: 25 },
        { type: 'Market Access', value: 20 },
        { type: 'Clinical Trials', value: 15 },
        { type: 'Preceptorships', value: 5 }
      ]
    };

    // Dati per Stacked Bar Chart
    const stackedBarChartData = {
      title: 'Therapeutic Areas',
      data: [
        { area: 'Diabetes', board: 40, delphiPanel: 60 },
        { area: 'Rare Diseases', board: 50, delphiPanel: 50 },
        { area: 'Oncology', board: 60, delphiPanel: 40 }
      ]
    };

    // Creazione dell'oggetto JSON finale
    const exportData = {
      barChart: barChartData,
      pieChart: pieChartData,
      stackedBarChart: stackedBarChartData
    };

    // Converti i dati in stringa JSON
    const jsonString = JSON.stringify(exportData, null, 2);

    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob); // Corretto uso di URL.createObjectURL
    const a = document.createElement('a');
    a.href = url;
    a.download = 'chart-data.json';
    a.click();

    URL.revokeObjectURL(url);
  }

}
