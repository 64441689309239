import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DemoService} from '../../../services/demo.service';

@Component({
  selector: 'app-responses-evaluation',
  templateUrl: './responses-evaluation.component.html',
  styleUrls: ['./responses-evaluation.component.scss']
})
export class ResponsesEvaluationComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private demoService: DemoService,
  ) {
  }

  id!: string;
  evaluation_sessions = null;
  suggestions = null;
  proposals = null;
  framework = null;
  randomDate: Date;
  allOpened = false;
  onlyNotAnswered = false;

  protected readonly Date = Date;

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      // tslint:disable-next-line:no-non-null-assertion
      this.id = params.get('id')!;
      this.init();
    });
  }

  init() {
    this.randomDate = new Date();
    switch (this.id) {
      case '1':
        this.evaluation_sessions = this.demoService.casoSarcomatoid.evaluation_sessions.slice();
        this.suggestions = this.demoService.casoSarcomatoid.suggestions.slice();
        const proposals = this.demoService.casoSarcomatoid.proposals.slice();
        proposals.forEach((proposal) => {
          proposal.framework.criteria.forEach((criterion) => {
            criterion.answer = {
              confidence: null,
              answer: null,
              feedback: '',
            };
          });
        });
        this.proposals = proposals;
        this.framework = this.proposals[0].framework;
        break;
    }
  }

  getIconColorForCriteria(criteria: any): string {
    return this.criterionIsCompleted(criteria) ? 'green-color' : 'orange-color';
  }

  criterionIsCompleted(criterion): boolean {
    console.log(criterion)
    return criterion.answer.answer && criterion.answer.confidence !== null && criterion.answer.feedback;
  }
}
