<!-- Blocco 1 -->
<div class="container-fluid accordion-container">
    <div class="container">
        <mat-accordion class="accordion" multi>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="index">1</span>
                        Timeline
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="stepper-container col-12">
                        <p class="headline float-left">
                            Evaluation Session
                        </p>
                        <p class="headline float-right">
                            Report
                        </p>
                        <div class="timeline">
                            <div class="step-container" [ngClass]="session.tracker == true ? 'current' : ''" *ngFor="let session of evaluation_sessions_row_1; let i = index">
                              <ng-container>
                                <div class="step">{{i + 1}}</div>
                                <div class="step-label">{{session.decision_start}} <br> {{session.decision_end}}</div>
                              </ng-container>
                            </div>
                            <div class="step-container icon-container" *ngIf="evaluation_sessions_row_1.length < evaluation_sessions_by_row">
                              <div class="step">
                                  <i class="icon-Flag"></i>
                              </div>
                            </div>
                            <!-- <div class="step-container current">
                                <div class="step">2</div>
                                <div class="step-label">17 Luglio 2020</div>
                            </div> -->
                        </div>
                    </div>
                    <div class="stepper-container col-12" *ngIf="evaluation_sessions_row_1.length >= evaluation_sessions_by_row">
                        <div class="timeline until-end">
                            <div class="step-container" [ngClass]="session.tracker == true ? 'current' : ''" *ngFor="let session of evaluation_sessions_row_2; let i = index">
                              <ng-container>
                                <div class="step">{{evaluation_sessions_by_row + i+1}}</div>
                                <div class="step-label">{{session.decision_start}}</div>
                              </ng-container>
                            </div>
                            <div class="step-container icon-container">
                              <div class="step">
                                  <i class="icon-Flag"></i>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row col-12 d-flex justify-content-end mb-3">
                  <button class="mr-auto mb-1" style="background-color: #FF0070 !important;"
                          [routerLink]="['/responses-evaluation/'+id]"
                          routerLinkActive="router-link-active" mat-flat-button
                          color="primary">Answer to Questionnaire</button>
                    <div class="detailed-button mr-3">
                        <button mat-flat-button color="primary">Close</button>
                        <i class="icon-lock-open"></i>
                        <label>{{modelStartNewEvaluation.closing_date}}</label>
                    </div>
                    <button mat-flat-button color="primary" [routerLink]="['/decision/'+id+'/dashboard']" routerLinkActive="router-link-active" class="mr-3">Dashboard</button>
                  <button mat-flat-button color="primary" [routerLink]="['/decision/'+id+'/task-management']">Task management</button>
                </div>

              <div class="row col-12 d-flex justify-content-end mb-3">
                <button mat-flat-button color="primary" class="mr-2" (click)="openNetworkAnalisys()">Open Network analisys</button>
                <button mat-flat-button color="primary" (click)="openContextDiscovery()">Context discovery</button>
              </div>

            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<!-- Blocco 2 -->
<div class="container-fluid accordion-container">
    <div class="container">
        <mat-accordion class="accordion" multi>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="index">2</span>
                        Tips
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="container px-0">
                        <div class="box-info mb-4 position-relative" *ngFor="let suggest of suggestions">
                          <ng-container>
                            <i class="icon-warning-triangle float-left col-2 flex-column big-icon"></i>
                            <p class="description col-10">
                              <ng-container *ngFor="let idea of suggest">
                                <span class="title">{{idea.title}}</span>
                                {{idea.description}}
                              </ng-container>
                            </p>
                            <i class="icon-circle_info small-icon abs-br"></i>
                          </ng-container>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<!-- Blocco 3 -->
<!--
<div class="container-fluid accordion-container">
    <div class="container">
-->
<div class="accordion-container container-fluid px-0">
    <div class="container-fluid px-0">
        <mat-accordion class="accordion" multi>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header class="container">
                    <mat-panel-title>
                        <span class="index">3</span>
                        Evaluation session management
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <!-- colored box -->
                <div class="row container pt-4 pb-2 mx-auto">
                  <div class="col-12 box box-two-row border-0 rounded pb-0" [ngClass]="getClassSuggestion(proposal)" *ngFor="let proposal of proposals">
                    <ng-container>
                      <div class="row mt-0 mb-1">
                          <i class="icon-edit abs-tr p-2 main-color" (click)="editProposal(proposal)"></i>
                          <div class="col-1 icon-container">
                              <mat-checkbox></mat-checkbox>
                          </div>
                          <div class="col-4 col-lg-2 info-element">
                              <p class="title">Proposal</p>
                              <p class="description">{{proposal.title}}</p>
                          </div>
                          <div class="col-3 col-lg-2 info-element">
                              <p class="title">Proposed by</p>
                              <div class="content-grid">
                                  <img class="avatar-img"
                                      [src]="proposal.proposed_by_avatar">
                                  <div>
                                      <p class="description">{{proposal.proposed_by}}</p>
                                      <p class="subline">{{proposal.proposed_by_role}}</p>
                                  </div>
                              </div>
                          </div>
                          <div class="col-4 col-lg-2 info-element">
                              <p class="title">Creation session</p>
                              <p class="description">{{proposal.creation_session}}</p>
                          </div>
                          <div class="col-5 offset-1 col-lg-2 offset-lg-0 info-element">
                              <p class="title">When evaluated</p>
                              <p class="description">{{proposal.when_evaluated}}</p>
                          </div>
                          <div class="col-5 col-lg-3 info-element">
                              <p class="title">Score obtained</p>
                              <div class="d-flex">
                                  <mat-progress-bar [value]="(proposal.score/proposal.score_value)*100"></mat-progress-bar>
                                  <mat-label *ngIf="proposal.score">{{proposal.score}}/{{proposal.score_value}}</mat-label>
                              </div>
                          </div>
                      </div>
                      <div class="footer">
                          {{proposal.suggestion}}
                          <i class="abs-br px-3 abs-center-y d-table" [ngClass]="getIconSuggestion(proposal)"></i>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <!-- Scelta opzionale delle proposte two buttons -->
                <div class="container-fluid bg-white px-0 mx-n-24 w-100 border-bottom-grey">
                  <div class="container py-4 px-0">
                      <div class="row justify-content-around mt-4 mb-5">
                          <button mat-flat-button color="primary" (click)="generateProposal()">
                            GENERATE PROPOSAL
                              <label class="label-bottom">The system can generate proposals with respect to
                                acquired information</label>
                          </button>
                          <button mat-flat-button color="primary" (click)="newProposal()">Add Proposal</button>
                      </div>
                  </div>
              </div>

                <!-- Avviare una nuova sessione di valutazione yes or no -->
                <div class="container-fluid bg-white px-0 mx-n-24 w-100 border-top-grey border-bottom-grey">
                    <div class="container py-4 px-5">
                        <div class="row py-4 two-radio-box mt-0 bg-transparent border-bottom-0">
                            <div class="container px-0">
                                <p class="title">Do you want to start a new evaluation session?</p>
                                <mat-radio-group class="col-12 mat-radio-box px-0" aria-label="Select an option" name="start_new_evaluation" [(ngModel)]="startNewEvaluationChoice">
                                    <mat-grid-list cols="12" rowHeight="100px">
                                        <ng-container>
                                          <mat-grid-tile colspan="6" (click)="onClickStartNewSession(true)">
                                              <mat-radio-button [value]="true" class="col-12">Yes</mat-radio-button>
                                          </mat-grid-tile>
                                          <mat-grid-tile colspan="6" (click)="onClickStartNewSession(false)">
                                              <mat-radio-button [value]="false" class="col-12">No</mat-radio-button>
                                          </mat-grid-tile>
                                        </ng-container>
                                    </mat-grid-list>
                                </mat-radio-group>
                            </div>
                        </div>
                        <div class="row justify-content-center" *ngIf="startNewEvaluationChoice && startNewEvaluation == false">
                            <button mat-flat-button color="primary" (click)="onClickConfirmStartNewSession()">Confirm</button>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="startNewEvaluation">
                  <!-- two datepicker -->
                  <div class="container-fluid bg-white px-0 mx-n-24 w-100 border-bottom-grey">
                      <div class="container py-4 px-0">
                          <div class="row px-4">
                              <mat-form-field class="col-6 col-lg-4 datepicker-custom-button mt-1" appearance="fill">
                                  <mat-label>Evaluation session start date</mat-label>
                                  <input matInput [matDatepicker]="dp_startEvaluationDate" name="start_evaluation_date" class="ng-trim-ignore" (focus)="dp_startEvaluationDate.open()" [(ngModel)]="modelStartNewEvaluation.start_date">
                                  <mat-datepicker-toggle matSuffix [for]="dp_startEvaluationDate">
                                      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #dp_startEvaluationDate></mat-datepicker>
                              </mat-form-field>
                              <mat-form-field class="col-6 col-lg-4 datepicker-custom-button mt-1" appearance="fill">
                                  <mat-label>Evaluation session end date</mat-label>
                                  <input matInput [matDatepicker]="dp_endEvaluationDate" name="end_evaluation_date" class="ng-trim-ignore" (focus)="dp_endEvaluationDate.open()" [(ngModel)]="modelStartNewEvaluation.end_date">
                                  <mat-datepicker-toggle matSuffix [for]="dp_endEvaluationDate">
                                      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #dp_endEvaluationDate></mat-datepicker>
                              </mat-form-field>
                          </div>
                      </div>
                  </div>


                  <!-- settaggi round -->
                  <div class="container-fluid bg-white px-0 mx-n-24 w-100 border-bottom-grey">
                      <div class="container py-4 px-4 px-md-0">
                          <div class="row my-3 px-4">
                              <mat-label class="label-box ml-2 font-weight-bold">
                                  Round Settings
                                  <i class="icon-circle_info grey-color"></i>
                              </mat-label>
                          </div>
                          <div class="row my-4">
                              <div class="toggle-container col-5 d-inline-block" appearance="fill">
                                  <i class="icon-circle_info abs-tr"></i>
                                  <mat-label>Allow the user to create new proposals by modifying existing ones</mat-label>
                                  <div>
                                      <span class="mr-2">No</span>
                                      <mat-slide-toggle class="toggle" [(ngModel)]="modelStartNewEvaluation?.settings.review_enabled">Yes</mat-slide-toggle>
                                  </div>
                                  <i class="abs-tr icon-circle_info grey-color"></i>
                              </div>
                              <div class="toggle-container offset-1 col-5 d-inline-block" appearance="fill">
                                  <i class="icon-circle_info abs-tr"></i>
                                  <mat-label>Allow the user to propose new proposals
                                  </mat-label>
                                  <div>
                                      <span class="mr-2">No</span>
                                      <mat-slide-toggle class="toggle" [(ngModel)]="modelStartNewEvaluation?.settings.provide_new_proposal_enabled">Yes</mat-slide-toggle>
                                  </div>
                                  <i class="abs-tr icon-circle_info grey-color"></i>
                              </div>
                          </div>
                          <div class="row my-4">
                              <div class="toggle-container col-5 d-inline-block" appearance="fill">
                                  <i class="icon-circle_info abs-tr"></i>
                                  <mat-label>Degree of confidence</mat-label>
                                  <div>
                                      <span class="mr-2">No</span>
                                      <mat-slide-toggle class="toggle" [(ngModel)]="modelStartNewEvaluation?.settings.degree_of_confidence_enabled">Yes</mat-slide-toggle>
                                  </div>
                                  <div class="red-subline">
                                    It is recommended to delete the degree of confidence because the system is unable to
                                    provide a comprehensive risk assessment
                                  </div>
                                  <i class="abs-tr icon-circle_info grey-color"></i>
                              </div>
                              <div class="toggle-container offset-1 col-5 d-inline-block" appearance="fill">
                                  <i class="icon-circle_info abs-tr"></i>
                                  <mat-label>Ability to provide feedback on the evaluation
                                    carried out</mat-label>
                                  <div>
                                      <span class="mr-2">No</span>
                                      <mat-slide-toggle class="toggle" [(ngModel)]="modelStartNewEvaluation?.settings.provide_feedback_enabled">Yes</mat-slide-toggle>
                                  </div>
                                  <div class="red-subline">
                                    It is recommended to delete the degree of confidence because the system is unable to
                                    provide a comprehensive risk assessment
                                  </div>
                                  <i class="abs-tr icon-circle_info grey-color"></i>
                              </div>
                          </div>
                          <div class="row my-4">
                              <div class="toggle-container col-5 d-inline-block" appearance="fill">
                                  <i class="icon-circle_info abs-tr"></i>
                                  <mat-label>Make the questionnaires anonymous</mat-label>
                                  <div>
                                      <span class="mr-2">No</span>
                                      <mat-slide-toggle class="toggle" [(ngModel)]="modelStartNewEvaluation?.settings.anonymous_survey_enabled">Yes</mat-slide-toggle>
                                  </div>
                                  <i class="abs-tr icon-circle_info grey-color"></i>
                              </div>
                          </div>
                      </div>
                  </div>

                  <!-- modifica team -->
                  <div class="container-fluid px-0 mx-n-24 w-100">
                    <div class="container py-4 px-0">
                        <div class="row my-3 px-4">
                            <mat-label class="label-box ml-2 font-weight-bold">
                              Edit the team
                                <i class="icon-circle_info grey-color"></i>
                            </mat-label>
                        </div>
                        <div class="row px-4 px-md-0">
                            <div class="col-12 px-0">

                                <!-- Box User -->
                                <div class="box px-0 box-two-row" *ngFor="let team_member of modelStartNewEvaluation.team_members; let i = index">
                                  <ng-container>
                                    <mat-form-field class="input-primary col-6 col-md-2">
                                        <input matInput name="team_member_name{{i}}" [(ngModel)]="team_member.username" placeholder="Name Surname" required>
                                    </mat-form-field>
                                    <mat-form-field class="input-primary col-4 col-md-2">
                                      <input matInput name="team_member_email{{i}}" [(ngModel)]="team_member.email" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
                                  </mat-form-field>
                                    <mat-form-field class="select-primary col-6 col-md-3" appearance="fill">
                                        <mat-label>Professional role</mat-label>
                                        <mat-select name="team_member_professional_role{{i}}" [(ngModel)]="team_member.organization_professional_role_id">
                                          <mat-option value="" selected></mat-option>
                                          <mat-option *ngFor="let professionalRole of professionalRoles" [value]=professionalRole.id>{{professionalRole.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="select-primary col-5 col-md-3" appearance="fill">
                                        <mat-label>Role in the process</mat-label>
                                        <mat-select name="team_member_process_role{{i}}" [(ngModel)]="team_member.process_role">
                                          <mat-option value="" selected></mat-option>
                                          <mat-option *ngFor="let processRole of process_roles" [value]="processRole.key">{{processRole.title}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="select-primary col-3 col-md-2" appearance="fill">
                                        <mat-label>Weight</mat-label>
                                        <mat-select name="team_member_weight{{i}}" [(ngModel)]="team_member.weight">
                                            <mat-option value=""></mat-option>
                                            <mat-option [value]="100">100</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <div class="mt-4">
                                        <div class="toggle-container col-4 d-inline-block" appearance="fill">
                                            <mat-label>Enabled for full assessment</mat-label>
                                            <div>
                                                <span class="mr-2">No</span>
                                                <mat-slide-toggle class="toggle" name="team_member_complete_process_enabled{{i}}" [(ngModel)]="team_member.complete_process_enabled">Yes</mat-slide-toggle>
                                            </div>
                                        </div>
                                        <div class="toggle-container col-4 d-inline-block" appearance="fill">
                                            <mat-label>Authorized to review</mat-label>
                                            <div>
                                                <span class="mr-2">No</span>
                                                <mat-slide-toggle class="toggle" name="team_member_review_enabled{{i}}" [(ngModel)]="team_member.review_enabled">Yes</mat-slide-toggle>
                                            </div>
                                        </div>
                                        <div class="toggle-container col-4 d-inline-block" appearance="fill">
                                            <mat-label>Enabled to provide new proposals</mat-label>
                                            <div>
                                                <span class="mr-2">No</span>
                                                <mat-slide-toggle class="toggle" name="team_member_provide_new_proposal_enabled{{i}}" [(ngModel)]="team_member.provide_new_proposal_enabled">Yes</mat-slide-toggle>
                                            </div>
                                        </div>
                                    </div>
                                    <i class="icon-edit abs-tr main-color"></i>
                                  </ng-container>
                                </div>
                            </div>
                            <button class="col-12 big-button mb-3" mat-raised-button>
                                <i class="icon-big_add"></i>
                                Add team member
                            </button>
                        </div>

                        <div class="row justify-content-center">
                            <button mat-flat-button color="primary" (click)="onClickSaveAndStartSession()">Save and start session</button>
                        </div>
                    </div>
                </div>
                </ng-container>

            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<!-- Buttons -->
<div class="buttons-container container py-4">
    <button class="button-primary float-left" mat-button>
        <i class="icon-Backward-arrow-small"></i>
        Save and go back
    </button>
    <button class="button-primary float-right" mat-button>
        <i class="icon-Forward-arrow-small"></i>
        Save and close
    </button>
</div>



<ng-template #dialogTemplate>
  <div class="container">
    <div class="row">
      <div class="col-12" style="font-size: 25px;">
        Proposta Bot
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-12">
        Vuoi che generi una proposta per te?
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-6">
        <button color="primary" mat-raised-button (click)="confirmGenerateProposal()">Yes</button>
      </div>
      <div class="col-6">
        <button mat-raised-button (click)="dialog.closeAll()">Vuoi sapere come faccio?</button>
      </div>
    </div>
  </div>
</ng-template>
