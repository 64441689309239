<div class="container-fluid box-sub-header py-3 pt-lg-5">
  <div class="container">
    <mat-form-field class="col-12 col-lg-6 select-custom-button" appearance="fill">
      <mat-label>Organization</mat-label>
      <input matInput name="organization_name" value="Butterfly Decisions" readonly>
    </mat-form-field>
    <mat-form-field class="col-12 col-lg-6 datepicker-custom-button" appearance="fill">
      <mat-label>Due date</mat-label>
      <input matInput name="evaluation_end" placeholder="Due date" readonly>
    </mat-form-field>
  </div>
</div>


<div class="container-fluid accordion-container px-0 px-md-3">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">1</span>
            Goals
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-12 px-2">
            <div class="box px-0 box-two-row border-0 bg-transparent">
              <mat-label class="box-title px-3">Primary</mat-label>
              <mat-form-field class="input-primary col-12 col-lg-6">
                <input matInput [ngModel]="framework.primary_target.title" placeholder="{{'generic.title' | translate}}" readonly>
              </mat-form-field>
              <mat-form-field class="datepicker-primary col-7 col-lg-6 px-1" appearance="fill">
                <mat-label>Reach day</mat-label>
                <input matInput [value]="randomDate" placeholder="Date" readonly>
              </mat-form-field>
              <mat-form-field class="input-primary col-5 col-lg-6 pt-1">
                <input matInput placeholder="Unit" value="Score" readonly>
              </mat-form-field>
              <mat-form-field class="input-primary col-6 col-lg-6 pt-1">
                <input matInput placeholder="Target Value" value="The higher, the better" readonly>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="container-fluid accordion-container px-0 px-md-3">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">2</span>
            Evaluation
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container>
          <div class="row">
            <div class="col-12 col-lg-6 px-0 mb-0">
              <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                <mat-label class="box-title px-3 mb-0">Filters</mat-label>
              </div>
            </div>
          </div>
          <div class="row d-flex justify-content-center">
            <section style="padding: 0.75em 0.75em 0 0.75em;">
              <mat-checkbox class="col-3" [(ngModel)]="onlyNotAnswered">
                Only not Answered
              </mat-checkbox>
            </section>
            <section style="padding: 0.75em 0.75em 0 0.75em;">
              <mat-checkbox class="col-3" [(ngModel)]="allOpened">
                Show all opened
              </mat-checkbox>
            </section>
          </div>
        </ng-container>
        <div class="row">
          <ng-container *ngFor="let proposal of proposals; let proposal_index = index">
            <div class="col-12 col-lg-12 px-0 mt-5 mb-2">
              <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                <mat-label class="box-title px-3 mb-0" style="font-size: 16px">Proposal: {{proposal.title}}</mat-label>
              </div>
            </div>
            <ng-container *ngFor="let criteria of proposal.framework.criteria; let criteria_index = index">
              <div
                *ngIf="!onlyNotAnswered || (onlyNotAnswered && !criterionIsCompleted(criteria))"
                class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left"
              >
                <div class="container">
                  <mat-accordion class="accordion" multi [togglePosition]="'before'">
                    <mat-expansion-panel class="px-0" [expanded]="allOpened">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <span>{{criteria.title}}</span>
                          <i class="icon-circle_info text-white d-flex"></i>
                          <i class="icon-checkmark d-flex abs-tr mx-0" [ngClass]="getIconColorForCriteria(criteria)"></i>
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                      <div class="row bclaorder-bottom-grey pt-0 pb-4 my-3">
                        <small class="col-12">Description</small>
                        <div class="col-12">{{ criteria.description }}</div>

                        <!-- Allegati da chi ha aperto il tavolo -->
                        <div class="col-12 mt-5 mb-2" *ngIf="criteria?.evidenceData?.length > 0">
                          <mat-label class="label-box font-weight-bold">
                            Evidences Loaded
                          </mat-label>
                        </div>
                        <div class="col-12 mb-1" *ngFor="let attachment of criteria?.evidenceData">
                          <div class="attachment-row">
                                            <span class="name">
                                              <i class="icon-pdf"></i>
                                              <a [href]="attachment.link" target="_blank">{{ attachment.name }}</a>
                                            </span>
                          </div>
                        </div>

                        <div class="col-12 mt-4">
                          <div class="d-flex ml-2 my-2 toogle-button-container justify-content-center">
                            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="criteria.answer.answer">
                              <mat-button-toggle
                                class="green" value="AGREE">Agree
                              </mat-button-toggle>
                              <mat-button-toggle
                                class="red" value="DISAGREE">Disagree
                              </mat-button-toggle>
                              <mat-button-toggle
                                class="yellow" value="ABSTAINED">Abstained
                              </mat-button-toggle>
                            </mat-button-toggle-group>
                          </div>
                        </div>

                        <div class="col-12 mt-5">
                          <mat-form-field class="full-width" appearance="fill">
                            <mat-label class="label-box font-weight-bold">
                              Feedback
                              <!-- <i class="icon-cestino grey-color abs-tr" (click)="removeFeedback(proposal_index, criteria_index)" ></i> -->
                            </mat-label>
                            <textarea class="description my-2" matInput required
                                      rows="3" [(ngModel)]="criteria.answer.feedback"></textarea>
                          </mat-form-field>
                        </div>
                        <div class="col-12 links-container">
                          <!-- <button *ngIf="criteria?.answer?.feedback == undefined" mat-button color="primary" (click)="addFeedback(proposal_index, criteria_index)">
                              <i class="icon-Add mr-2"></i>
                              {{'components.responses.add-feedback' | translate}}
                          </button> -->

                          <div>
                            <button mat-button color="primary" [matMenuTriggerFor]="menuAttachment">
                              <i class="icon-upload mr-2"></i>
                              Add evidences
                            </button>
                            <mat-menu #menuAttachment="matMenu">
                              <button mat-menu-item>
                                <mat-icon aria-hidden="false">file_upload</mat-icon>
                                Pubmed
                              </button>
                              <button mat-menu-item>
                                <mat-icon aria-hidden="false">file_upload</mat-icon>
                                File
                              </button>
                            </mat-menu>
                          </div>
                        </div>
<!--                        <div class="col-12" *ngIf="criteria?.attachment_expert?.length > 0">-->
<!--                          <mat-label class="label-box font-weight-bold">-->
<!--                            {{ 'components.responses.evidences-loaded-by-expert' | translate }}-->
<!--                          </mat-label>-->
<!--                        </div>-->
<!--                        <div class="col-12"-->
<!--                             *ngFor="let attachment_expert of criteria?.attachment_expert; let attachIndex = index">-->
<!--                          <div class="attachment-row">-->
<!--                                          <span class="name">-->
<!--                                            <i class="icon-pdf"></i>-->
<!--                                            <a [href]="attachment_expert.link"-->
<!--                                               target="_blank">{{ attachment_expert.title }}</a>-->
<!--                                          </span>-->
<!--                            <span class="size float-right"-->
<!--                                  *ngIf="!decision_table?.closing_date && evaluation_session?.collecting && !response_stamps?.completed">-->
<!--                                            {{ 'generic.buttons.delete' | translate }}-->
<!--                              <i class="icon-cestino main-color"-->
<!--                                 (click)="onClickRemoveEvidence(attachIndex, criteria)"></i>-->
<!--                                        </span>-->
<!--                          </div>-->
<!--                        </div>-->
                      </div>
                      <div class="row border-bottom-grey pt-0 pb-4 my-3">
                        <small class="col-12">How certain are you about your assessment?</small>

                        <mat-slider
                          class="w-100 mt-4 cdk-focused"
                          min="0" max="100"
                          step="1"
                          [(ngModel)]="criteria.answer.confidence"
                          [thumbLabel]="true"
                          color="primary">
                        </mat-slider>
                        <div class="d-flex justify-content-between w-100 mb-2 px-2">
                          <small class="float-left">Absolutely not sure</small>
                          <small class="float-right">Absolutely sure</small>
                        </div>
                      </div>

                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>


<div class="container-fluid mt-5">
  <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto">
    <button class="px-5" mat-flat-button color="primary">
      Save as draft
    </button>
  </div>
</div>


<!-- Buttons -->
<div class="buttons-container container py-4">
  <button class="button-primary float-left" mat-button [routerLink]="['/']">
    <i class="icon-Backward-arrow-small"></i>
    Go back
  </button>
  <button class="button-primary float-right" mat-button>
    <i class="icon-Forward-arrow-small"></i>
    Save and Send
  </button>
</div>




