<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<ng-container *ngIf="data">
  <!--  Project Details  -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index"></span>
              Project details
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="container my-5">
            <mat-card class="login-card">
              <mat-card-content>
                <form (submit)="updateProjectDetails()">
                  <mat-form-field class="full-width">
                    <mat-label>Title</mat-label>
                    <input matInput [(ngModel)]="model.projectDetails.title" name="title" required>
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>Description</mat-label>
                    <textarea matInput [(ngModel)]="model.projectDetails.description"
                              name="description"></textarea>
                  </mat-form-field>
                  <div class="row justify-content-center my-3">
                    <a mat-button color="warn"
                       *ngIf="areObjectsDifferent(model.projectDetails, data.projectDetails)"
                       (click)="resetProjectDetails()" class="mr-2">Cancel</a>
                    <button mat-raised-button color="primary" type="submit">Update</button>
                  </div>
                </form>
              </mat-card-content>
            </mat-card>
          </div>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!--  Decision Tables  -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index"></span>
              Decision Tables
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container class="my-5">
            <div class="container background-filters py-3 px-5 mt-3">
              <div class="row justify-content-between">
                <div class="purple-color">
                  <h2>Advanced search</h2>
                </div>
                <div>
                  <button class="clear-filter py-2 px-4">Search filters<i
                    class="icon-Close ml-2 size"></i></button>
                </div>
              </div>
              <div class="row d-flex justify-content-between align-items-center m-0">
                <div class="col-12 col-md-3">
                  <mat-form-field class="datepicker-primary wp-normal color-default mx-2" appearance="fill">
                    <mat-label>Start date</mat-label>
                    <input matInput class="ng-trim-ignore" [placeholder]="'GG/MM/AAAA' | translate"
                           [matDatepicker]="pickerStartDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStartDate></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-3">
                  <mat-form-field class="datepicker-primary wp-normal color-default" appearance="fill">
                    <mat-label>End date</mat-label>
                    <input matInput class="ng-trim-ignore" [placeholder]="'GG/MM/AAAA' | translate"
                           [matDatepicker]="pickerEndDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEndDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="row d-flex justify-content-between mx-0 mt-2">
                <div class="col-12 col-md-12 mt-4">
                  <mat-form-field class="full-width">
                    <input matInput name="searchKeywords" type="text"
                           placeholder="Decision table title...">
                    <mat-icon class="with-cursor" matSuffix aria-label="Clear">close
                    </mat-icon>
                    <mat-icon class="purple-color with-cursor" matSuffix aria-label="Clear">search
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="my-5">
              <div class="container">
                <div class="row">
                  <div class="col-12 text-center mb-4 mt-2">
                    <p>No decision table available</p>
                  </div>
                  <div class="col-12 d-flex justify-content-center my-5">
                    <button class="btn-custom border-0 d-flex align-items-center px-5 py-2">
                      <mat-icon class="mr-2">play_circle_outlined</mat-icon>
                      Add new decision table
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!--  Surveys  -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index"></span>
              Surveys
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container class="my-5">
            <div class="container background-filters py-3 px-5 mt-3">
              <div class="row justify-content-between">
                <div class="purple-color">
                  <h2>Advanced search</h2>
                </div>
                <div>
                  <button class="clear-filter py-2 px-4">Search filters<i
                    class="icon-Close ml-2 size"></i></button>
                </div>
              </div>
              <div class="row d-flex justify-content-between align-items-center m-0">
                <div class="col-12 col-md-3">
                  <mat-form-field class="datepicker-primary wp-normal color-default mx-2" appearance="fill">
                    <mat-label>Start date</mat-label>
                    <input matInput class="ng-trim-ignore" [placeholder]="'GG/MM/AAAA' | translate"
                           [matDatepicker]="pickerStartDate2">
                    <mat-datepicker-toggle matSuffix [for]="pickerStartDate2"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStartDate2></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-3">
                  <mat-form-field class="datepicker-primary wp-normal color-default" appearance="fill">
                    <mat-label>End date</mat-label>
                    <input matInput class="ng-trim-ignore" [placeholder]="'GG/MM/AAAA' | translate"
                           [matDatepicker]="pickerEndDate2">
                    <mat-datepicker-toggle matSuffix [for]="pickerEndDate2"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEndDate2></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div class="row d-flex justify-content-between mx-0 mt-2">
                <div class="col-12 col-md-12 mt-4">
                  <mat-form-field class="full-width">
                    <input matInput name="searchKeywords" type="text"
                           placeholder="Survey title...">
                    <mat-icon class="with-cursor" matSuffix aria-label="Clear">close
                    </mat-icon>
                    <mat-icon class="purple-color with-cursor" matSuffix aria-label="Clear">search
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="my-5">
              <div class="container">
                <div class="row">
                  <div class="col-12 text-center mb-4 mt-2">
                    <p>No survey available</p>
                  </div>
                  <div class="col-12 d-flex justify-content-center my-5">
                    <button class="btn-custom border-0 d-flex align-items-center px-5 py-2">
                      <mat-icon class="mr-2">play_circle_outlined</mat-icon>
                      Add new survey
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!--  P.I.C.O.  -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index"></span>
              P.I.C.O.
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="container my-5">
            <mat-card class="login-card">
              <mat-card-content>
                <form>
                  <mat-form-field class="full-width">
                    <mat-label>Patients</mat-label>
                    <input matInput [(ngModel)]="data.pico.patients" name="patients" required/>
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>Intervention</mat-label>
                    <input matInput [(ngModel)]="data.pico.intervention" name="intervention"
                           required>
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>Comparison</mat-label>
                    <input matInput [(ngModel)]="data.pico.comparison" name="comparison" required>
                  </mat-form-field>

                  <mat-form-field class="full-width">
                    <mat-label>Outcomes</mat-label>
                    <input matInput [(ngModel)]="data.pico.outcomes" name="outcomes" required>
                  </mat-form-field>

                  <div class="{{ picoQuestionStatus !== 'ready' ? 'row justify-content-center my-3' : '' }}">
                    <ng-container *ngIf="!picoQuestionStatus">
                      <button mat-raised-button color="primary" (click)="generatePICOQuestion()">
                        Generate P.I.C.O. question
                      </button>
                    </ng-container>
                    <ng-container *ngIf="picoQuestionStatus === 'pending'">
                      <mat-spinner diameter="40"></mat-spinner>
                    </ng-container>
                    <ng-container *ngIf="picoQuestionStatus === 'ready'">
                      <div class="box flex-column my-0 pb-0 pt-5 px-3 bordered">
                        <mat-form-field class="full-width">
                          <mat-label>P.I.C.O. question generated</mat-label>
                          <textarea
                            disabled
                            class="description"
                            matInput rows="4"
                            name="pico_response"
                            [(ngModel)]="data.pico.response"
                          ></textarea>
                        </mat-form-field>
                      </div>
                    </ng-container>
                  </div>
                </form>
              </mat-card-content>
            </mat-card>
          </div>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!--  BiblioSearch  -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index"></span>
              Literature review
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="container my-5">
            <mat-card class="login-card">
              <mat-card-content>
                <mat-chip-list aria-label="Keyword List">
                  <mat-chip
                    *ngFor="let keyword of data.biblio.keywords"
                    color="accent"
                    selected>
                    {{ keyword }}
                  </mat-chip>
                </mat-chip-list>
                <div class="row justify-content-center mt-5 mb-3"
                     *ngIf="!biblioSearchStatus || biblioSearchStatus === 'pending';">
                  <button mat-raised-button color="primary" (click)="generateBiblioTable()"
                          *ngIf="!biblioSearchStatus; else elseBlockIntern">
                    Search on pubmed with Virtual Assistant
                  </button>
                  <ng-template #elseBlockIntern>
                    <mat-spinner diameter="40"></mat-spinner>
                  </ng-template>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="container my-5" *ngIf="biblioSearchStatus === 'ready'">
            <mat-table [dataSource]="biblioSearchDataSource" matSort>
              <!-- Definizione delle colonne -->
              <ng-container matColumnDef="articleTitle">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
                <mat-cell *matCellDef="let article">{{ article.articleTitle }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="authors">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Authors</mat-header-cell>
                <mat-cell *matCellDef="let article">{{ article.authors }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="year">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Year</mat-header-cell>
                <mat-cell *matCellDef="let article">{{ article.year }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="studyType">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Study Type</mat-header-cell>
                <mat-cell *matCellDef="let article">{{ article.studyType }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="mainOutcome">
                <mat-header-cell *matHeaderCellDef>Main Outcome</mat-header-cell>
                <mat-cell *matCellDef="let article">{{ article.mainOutcome }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="riskOfBias">
                <mat-header-cell *matHeaderCellDef>Risk of Bias</mat-header-cell>
                <mat-cell *matCellDef="let article">{{ article.riskOfBias }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="consistency">
                <mat-header-cell *matHeaderCellDef>Consistency</mat-header-cell>
                <mat-cell *matCellDef="let article">{{ article.consistency }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="precision">
                <mat-header-cell *matHeaderCellDef>Precision</mat-header-cell>
                <mat-cell *matCellDef="let article">{{ article.precision }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="effectSize">
                <mat-header-cell *matHeaderCellDef>Effect Size</mat-header-cell>
                <mat-cell *matCellDef="let article">{{ article.effectSize }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="gradeQuality">
                <mat-header-cell *matHeaderCellDef>Grade Quality</mat-header-cell>
                <mat-cell *matCellDef="let article">{{ article.gradeQuality }}</mat-cell>
              </ng-container>

              <!-- Header e righe della tabella -->
              <mat-header-row *matHeaderRowDef="biblioSearchDisplayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: biblioSearchDisplayedColumns;"></mat-row>
            </mat-table>

          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!--  Simulator  -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index"></span>
              Simulator
            </mat-panel-title>
          </mat-expansion-panel-header>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!--  Automatic Reports  -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index"></span>
              Automatic Reports
            </mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!--  Project Tracking & Compliance  -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index"></span>
              Project Tracking & Compliance
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mt-4" style="width: 100%; text-align: center;">

              <!-- Nome Column -->
              <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Name </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.nome}} </td>
              </ng-container>

              <!-- Cognome Column -->
              <ng-container matColumnDef="cognome">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Surname </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.cognome}} </td>
              </ng-container>

              <!-- Email Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Email </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.email}} </td>
              </ng-container>

              <!-- Lettera d'incarico Column -->
              <ng-container matColumnDef="lettera">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Engagement Letter </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                  <mat-chip
                    [ngClass]="{'green-chip': element.lettera.status === 'yes', 'yellow-chip': element.lettera.status === 'pending', 'red-chip': element.lettera.status === 'no'}"
                    [disabled]="element.lettera.status === 'no'"
                    (click)="openAllegato(element.lettera.link)" >
                    {{ element.lettera.status }}
                  </mat-chip>
                </td>
              </ng-container>

              <!-- Conflitto di interessi Column -->
              <ng-container matColumnDef="conflitto">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Conflict of Interest </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">

                  <mat-chip
                    [ngClass]="{'green-chip': element.conflitto === 'yes', 'yellow-chip': element.conflitto === 'pending', 'red-chip': element.conflitto === 'no'}"
                    [disabled]="element.conflitto === 'no'">
                    {{ element.conflitto }}
                  </mat-chip>
                </td>
              </ng-container>

              <!-- Onboarding Progetto Column -->
              <ng-container matColumnDef="onboarding">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Onboarding Project </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.onboarding}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </ng-container>

          <div class="my-3">
            <div class="container">
              <div class="row">
                <div class="col-12 d-flex justify-content-center my-5">
                  <button class="btn-custom border-0 d-flex align-items-center px-5 py-2">
                    <mat-icon class="mr-2">play_circle_outlined</mat-icon>
                    Start Document Collection
                  </button>
                </div>
              </div>
            </div>
          </div>

          <style>
            .green-chip { background-color: #4CAF50; color: white; }
            .yellow-chip { background-color: #FFC107; color: black; }
            .red-chip { background-color: #F44336; color: white; }
            th, td { text-align: center; }
          </style>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

</ng-container>

<div class="buttons-container container py-4">
  <button class="button-primary float-left" mat-button [routerLink]="['/']" routerLinkActive="router-link-active">
    <i class="icon-Backward-arrow-small"></i>
    Go to home
  </button>
</div>
