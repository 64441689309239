<div class="container mt-5">
    <div class="cards-container ml-auto mr-auto">

        <!-- Card 1: Totale Progetti -->
        <mat-card class="mat-elevation-z4 custom-card-1">
            <!-- Titolo centrato -->
            <mat-card-title class="title-center">Total projects</mat-card-title>

            <mat-card-content class="card-content-center">
                <!-- Numero in grassetto, più grande -->
                <div class="total-number my-4">7</div>
                <!-- Testo più piccolo per i progetti chiusi -->
                <div class="smaller-text">Closed: 3</div>
            </mat-card-content>
        </mat-card>


        <!-- Card 2: Area Tematica -->
        <mat-card class="mat-elevation-z4 custom-card">
            <mat-card-content>
                <div #stackedBarChartContainer class="chart-container"></div>
            </mat-card-content>
        </mat-card>

        <!-- Card 3: Condivisione Progetti -->
        <mat-card class="mat-elevation-z4 custom-card">
            <mat-card-content>
                <div #barChartContainer class="chart-container"></div>
            </mat-card-content>
        </mat-card>

        <!-- Card 4: Delphi Board -->
        <mat-card class="mat-elevation-z4 custom-card">
            <mat-card-content>
                <div #pieChartContainer class="chart-container"></div>
            </mat-card-content>
        </mat-card>

    </div>
</div>

<div class="buttons-container container py-5">
    <button class="button-primary float-left" mat-button (click)="router.navigate(['/'])">
        <i class="icon-Backward-arrow-small"></i>
        Go back
    </button>
    <button class="button-primary float-right" mat-button (click)="exportDataToJSON()">
        <i class="icon-Forward-arrow-small"></i>
        Export Data
    </button>
</div>
